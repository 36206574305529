import React, { useEffect, useRef, useState } from 'react'
import './stureg.css'
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Footer from '../../components/Footer/Footer';
import dayjs from 'dayjs';
import NavBar from '../../components/Navigation bar/NavBar';
import { DateField } from '@mui/x-date-pickers/DateField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import HeadImage from '../../components/Header/HeadImage';

const BoostsReg = () => {

    useEffect(() => {
        document.title = 'Bansal Classes Sagar - Boosts Registration';
    }, []);

    const formRef = useRef(null)
    const scriptUrl = "https://script.google.com/macros/s/AKfycbzqoMa15BtdacK9aEGD0ekpyX_hFrX0dElTAxfHCTqWB8TucxEUh5uEA91q4AmRPlTZ/exec";
    const [loading, setLoading] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)

        fetch(scriptUrl, {
            method: 'POST',
            body: new FormData(formRef.current),
            mode: 'no-cors'

        }).then(res => {
            
            setLoading(false)
            formRef.current.reset();
            
            setGender("")
            setClasses("")
            setMode("")
            setBoard("")
            setDateSelected("")
        })
            .catch(err => console.log(err))
    }

    const [gender, setGender] = React.useState('');
    // const [value, setValue] = React.useState('');
    const [classes, setClasses] = React.useState('');
    const [course, setCourse] = React.useState('');
    const [mode, setMode] = React.useState('');
    const [board, setBoard] = React.useState([]);
    const [dateSelected, setDateSelected] = React.useState();
    const dayAfter = dayjs().add(2, 'day');


    const handleChangeGender = (event) => {
        setGender(event.target.value);
        console.log(gender)
    };

    const handleMode = (e) => {
        e.preventDefault()
        setMode(e.target.value);
        // console.log(e.target.value);
    }

    const handleCourse = (e) => {
        e.preventDefault()
        setCourse(e.target.value);
    }

    const handleBoard = (e) => {
        e.preventDefault()
        setBoard(e.target.value);
        // console.log(e.target.value);
    }

    // const startDate = dayjs().add(3, 'day');
    // console.log(startDate)

    const onlineDateList = ["02/10/2024", "03/10/2024", "04/10/2024", "05/10/2024", "06/10/2024", "26/10/2024"]
    const offlineDateList = ["06/10/2024", "26/10/2024"]




    return (

        <>
            <NavBar />

            <HeadImage title="Boosts Exam Registrations" />

            <div className="registration-form">
                <div className="heading">
                    <p className='form-reg-heading'>Registration Form</p>
                    <p className='form-reg-subheading'>please fill all the required informations</p>
                </div>
                <div className="boxform">
                    <form ref={formRef} onSubmit={handleSubmit} name="google-sheet">
                        {/* <div className="form-box"> */}



                        <div className="spec-form">
                            <TextField
                                required
                                id="outlined-required"
                                label="Your First Name"
                                name="First Name"
                            // defaultValue="Hello World" 
                            // placeholder='Your First'
                            />

                            <TextField
                                required
                                id="outlined-required"
                                label="Your Last Name"
                                name='Last Name'
                            // defaultValue="Hello World" 
                            // placeholder='Your First'
                            />

                            <TextField
                                required
                                id="outlined-required"
                                label="Father's Name"
                                name="Father's Name"
                            // defaultValue="Hello World" 
                            // placeholder='Your First'
                            />

                            <TextField
                                required
                                id="outlined-required"
                                label="Mother's Name"
                                name="Mother's Name"
                            // defaultValue="Hello World" 
                            // placeholder='Your First'
                            />

                            <FormControl required fullWidth>
                                <InputLabel id="gender-label">Gender</InputLabel>
                                <Select
                                    labelId="gender-label"
                                    id="gender"
                                    name="gender"
                                    value={gender}
                                    onChange={handleChangeGender}
                                    label="Gender"

                                >
                                    <MenuItem value={"male"}>Male</MenuItem>
                                    <MenuItem value={"female"}>Female</MenuItem>
                                    <MenuItem value={"other"}>Other</MenuItem>
                                </Select>
                            </FormControl>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateField']}>
                                    <DateField label="Date of Birth" name='Date of Birth'
                                        className='date-picker'
                                        format='DD/MM/YYYY' />
                                </DemoContainer>
                            </LocalizationProvider>

                            <FormControl required fullWidth>
                                <InputLabel id="class-label">Class</InputLabel>
                                <Select
                                    labelId="class-label"
                                    id="class"
                                    name="class"
                                    value={classes}
                                    onChange={(event) => {
                                        setClasses(event.target.value)
                                    }}
                                    label="Class"
                                    defaultValue={"7"}
                                    placeholder='Select Class'
                                >
                                    <MenuItem value={"7"}>7<sup>th</sup></MenuItem>
                                    <MenuItem value={"8"}>8<sup>th</sup></MenuItem>
                                    <MenuItem value={"9"}>9<sup>th</sup></MenuItem>
                                    <MenuItem value={"10"}>10<sup>th</sup></MenuItem>
                                    <MenuItem value={"11"}>11<sup>th</sup></MenuItem>
                                    <MenuItem value={"12"}>12<sup>th</sup></MenuItem>
                                    <MenuItem value={"13"}>12<sup>th</sup> Passed</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl required fullWidth>
                                <InputLabel id="Course-label">Course</InputLabel>
                                <Select
                                    labelId="Course-label"
                                    id="Course"
                                    name="Course"
                                    value={course}
                                    onChange={handleCourse}
                                    label="Course"
                                >
                                    <MenuItem value={"Engineering"} >Engineering</MenuItem>
                                    <MenuItem value={"Medical"} >Medical</MenuItem>
                                    <MenuItem value={"Neev"}>Neev</MenuItem>
                                    <MenuItem value={"Pre-Nucleus"}>Pre Nucleus</MenuItem>
                                </Select>
                            </FormControl>

                            <TextField
                                required
                                id="outlined-required"
                                label="School Name"
                                name="School Name"
                            // defaultValue="Hello World" 
                            // placeholder='Your First'
                            />

                            <FormControl required fullWidth>
                                <InputLabel id="board-name">Select Board</InputLabel>
                                <Select
                                    labelId="board-name-label"
                                    id="board-name"
                                    name="board-name"
                                    value={board}
                                    onChange={handleBoard}
                                    label="board name"
                                >
                                    <MenuItem value={"cbse"} >CBSE</MenuItem>
                                    <MenuItem value={"icse"} >ICSE</MenuItem>
                                    <MenuItem value={"other"} >Other</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl required fullWidth>
                                <InputLabel id="Test-Mode-label">Test Mode</InputLabel>
                                <Select
                                    labelId="Test-Mode-label"
                                    id="Test-Mode"
                                    name="Test-Mode"
                                    value={mode}
                                    onChange={handleMode}
                                    label="Test Mode"
                                >
                                    <MenuItem value={"online"} >Online</MenuItem>
                                    <MenuItem value={"offline"} >Offline</MenuItem>
                                </Select>
                            </FormControl>



                            {
                                mode === "offline" ?
                                    // <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    //     <DemoContainer components={['DateField']}>
                                    //         <DateField label="Test Date" name='Test Date'
                                    //             className='date-picker'
                                    //             helperText='Select Any Date after 3 days from present date '
                                    //             format='DD/MM/YYYY'
                                    //             disablePast
                                    //             minDate={dayAfter}
                                    //         />
                                    //     </DemoContainer>
                                    // </LocalizationProvider>
                                    <FormControl required fullWidth>
                                    <InputLabel id="Mode-label">Select Date</InputLabel>
                                    <Select
                                        labelId="Test-offline-date"
                                        id="Test-date"
                                        name="Test Date"
                                        value={dateSelected}
                                        onChange={(e) => setDateSelected(e.target.value)}
                                        label="Test Mode"
                                    >

                                        {
                                            offlineDateList.map((date) =>
                                                <MenuItem value={date} >{date}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>

                                    :

                                    <FormControl required fullWidth>
                                        <InputLabel id="Mode-label">Select Date</InputLabel>
                                        <Select
                                            labelId="Test-online-date"
                                            id="Test-date"
                                            name="Test Date"
                                            value={dateSelected}
                                            onChange={(e) => setDateSelected(e.target.value)}
                                            label="Test Mode"
                                        >

                                            {
                                                onlineDateList.map((date) =>
                                                    <MenuItem value={date} >{date}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>

                            }


                            <TextField
                                required
                                id="outlined-required"
                                label="Address"
                                name='address'
                                rows={4}

                                className='addressInput'
                            // defaultValue="Hello World" 
                            // placeholder='Your First'
                            />

                            <TextField
                                required
                                id="outlined-required"
                                label="City"
                                name='city'
                                rows={4}
                            // defaultValue="Hello World" 
                            // placeholder='Your First'
                            />

                            <TextField
                                required
                                id="outlined-required"
                                label="State"
                                name='state'
                            // rows={4}
                            // defaultValue="Hello World" 
                            // placeholder='Your First'
                            />

                            <TextField
                                required
                                id="outlined-required"
                                label="Zip"
                                type='number'
                                maxLength={6}
                                name='zip'
                            // defaultValue="Hello World" 
                            // placeholder='Your First'
                            />

                            <TextField
                                required
                                id="outlined-required"
                                label="Phone Number"
                                name='phone-number'
                            // defaultValue="Hello World" 
                            // placeholder='Your First'
                            />



                            <TextField
                                id="outlined-required"
                                label="Alternative Phone Number"
                                name='alternative-phone'
                            // defaultValue="Hello World" 
                            // placeholder='Your First'
                            />


                            <TextField
                                required
                                id="outlined-required"
                                label="Email"
                                type='email'
                                name='email'
                                className='email'
                            // defaultValue="Hello World" 
                            // placeholder='Your First'
                            />

                            <input type="submit" className="btn-regform myBtn"
                                value={loading ? "Loading..." : "Submit"} />
                        </div>

                    </form >
                    {/* </div> */}
                </div >
            </div >
            <Footer />

        </>
    )
}

export default BoostsReg